@use "sass:map";
@use "@angular/material" as mat;

@function getDeepMapValue($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@mixin communication-theme($theme) {
    $colors: mat.get-color-config($theme);
    $action: map.get($colors, action);
    $typography: mat.get-typography-config($theme);
    .todo-chip {
        color: getDeepMapValue($action, contrast, primary-static) !important;
        background: mat.get-color-from-palette($action, primary-static) !important;
    }
    .no-messages {
        padding: 1rem;
        @include mat.typography-level($typography, table-header);
    }
}
