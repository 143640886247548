@use "@angular/material" as mat;

@mixin notification-drawer-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $primary: map-get($colors, primary);
    $accent: map-get($colors, accent);
    $warn: map-get($colors, warn);
    $gray: map-get($colors, gray);
    $brand: map-get($colors, brand);

    .menuTitle {
        @include mat.typography-level($typography, body-medium);
        color: mat.get-color-from-palette($gray, darken-3)
    }

    .notificationMessage{
        @include mat.typography-level($typography, body2-normal);
        color: mat.get-color-from-palette($gray, body-default)
    }

    .menuHeader{
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider)
    }

    .notificationItem{
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider)
    }
    .icon{
        color: mat.get-color-from-palette($gray, darken-3)
    }
    .notificationTitle{
        @include mat.typography-level($typography, body-2);
        color: mat.get-color-from-palette($brand, dark-blue)
    }
}
