@use "@angular/material" as mat;

@mixin data-form-editor-theme($theme) {
    $typography: mat.get-typography-config($theme);
    $colors: mat.get-color-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $tint: map-get($colors, tint);

    .builder-border {
        border-radius: 0.25rem;
        border: 1px dashed mat.get-color-from-palette($foreground, divider);
    }
    .builder-preview {
        color: mat.get-color-from-palette($foreground, secondary-text);
        background: mat.get-color-from-palette($background, dialog);
    }
    .builder-drag-handle,
    .toolbox-drag-handle {
        color: mat.get-color-from-palette($foreground, secondary-text);
        opacity: 0.25;

        &:hover {
            opacity: 1;
        }
    }
    .builder-toolbox {
        border: 2px solid mat.get-color-from-palette($background, dialog);

        &.builder-toolbox-drop {
            background: mat.get-color-from-palette($tint, red-tint);
            border: 2px dotted mat.get-color-from-palette($foreground, divider);
            border-radius: 0.25rem;
        }
    }
    .builder-placeholder {
        background: mat.get-color-from-palette($tint, green-tint);
        border: 2px dotted mat.get-color-from-palette($foreground, divider);
        border-radius: 0.25rem;

        .builder-available-list & {
            border: none;
            background: mat.get-color-from-palette($tint, red-tint);
            min-height: 0 !important;
            height: 0;
        }
    }

    .builder-field {
        border-radius: 0.25rem;
        border: 1px hidden;

        &.builder-hover:hover {
            background: mat.get-color-from-palette($tint, blue-tint);
            border: 1px dotted mat.get-color-from-palette($foreground, divider);
        }
        &.builder-hover.depth-even:hover {
            background: mat.get-color-from-palette($tint, dark-gray-tint);
        }

        &.cdk-drag-preview {
            background: mat.get-color-from-palette($background, dialog);
            border: 1px solid mat.get-color-from-palette($foreground, divider);
        }
    }
    .builder-available {
        color: mat.get-color-from-palette($foreground, secondary-text);
        @include mat.typography-level($typography, subheading-1);
    }

    .builder-hover data-form-field:hover .builder-options {
        background: mat.get-color-from-palette($tint, blue-tint);
        border-left: 1px dotted mat.get-color-from-palette($foreground, divider);
        border-right: 1px dotted mat.get-color-from-palette($foreground, divider);
        border-top: 1px dotted mat.get-color-from-palette($foreground, divider);
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: -0.5rem;
        border-bottom-right-radius: -0.5rem;
    }
    .builder-hover.depth-even data-form-field:hover .builder-options {
        background: mat.get-color-from-palette($tint, dark-gray-tint);
    }
}
