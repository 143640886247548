@use "@angular/material" as mat;

@mixin inquiry-theme($theme) {
    $colors: mat.get-color-config($theme);
    $foreground: map-get($colors, foreground);
    $warn: map-get($colors, warn);
    $tint: map-get($colors, tint);
    $typography: mat.get-typography-config($theme);

    .table .body .row.message-display {
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .inquiry-overview {
        background: mat.get-color-from-palette($tint, yellow-tint);
        color: mat.get-contrast-color-from-palette($tint, yellow-tint);

        .header-panel {
            &:not(:last-child) {
                box-shadow: inset -1px 0px 1px rgba(0, 0, 0, 0.12);
            }
        }
        .physician {
            @include mat.typography-level($typography, subheading-2);
        }
    }
}
