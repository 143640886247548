@use "sass:map";
@use "@angular/material" as mat;

@mixin team-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $brand: map.get($colors, brand);
    $action: map.get($colors, action);
    $gray: map.get($colors, gray);

    .team-header {
        @include mat.typography-level($typography, body-2);
        color: mat.get-color-from-palette($brand, dark-blue);
    }

    .role-info {
        margin: 0px 10px;
    }

    .add-button {
        @include mat.typography-level($typography, caption);
        color: mat.get-color-from-palette($action, secondary-static);
    }
    .add-button-text {
        margin: 0px 0.5rem;
    }
}
