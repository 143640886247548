/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@use "@angular/material" as mat;
@use "sass:map";
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import "https://fonts.googleapis.com/css?family=Lato";

$fontConfig: (
    headline-1: mat.define-typography-level(112px, 112px, 300, "Lato", -0.0134em),
    headline-2: mat.define-typography-level(56px, 56px, 400, "Lato", -0.0089em),
    headline-3: mat.define-typography-level(45px, 48px, 400, "Lato", 0em),
    headline-4: mat.define-typography-level(34px, 40px, 400, "Lato", 0.0074em),
    headline-5: mat.define-typography-level(48px, 58px, 700, "Lato", 1.5px),
    headline-6: mat.define-typography-level(28px, 34px, 700, "Lato", 0.5px),
    display-4: mat.define-typography-level(112px, 112px, 300, "Lato", -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, "Lato", -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, "Lato", 0em),
    display-1: mat.define-typography-level(34px, 40px, 400, "Lato", 0.0074em),
    headline: mat.define-typography-level(48px, 58px, 700, "Lato", 1.5px),
    title: mat.define-typography-level(28px, 34px, 700, "Lato", 0.5px),
    subheading-2: mat.define-typography-level(20px, 24px, 700, "Lato", 0em),
    subheading-1: mat.define-typography-level(16px, 19px, 700, "Lato", 0.25px),
    body-1: mat.define-typography-level(14px, 20px, 400, "Lato", 0.25px),
    body-2: mat.define-typography-level(16px, 24px, 400, "Lato", 0em),
    body-medium: mat.define-typography-level(1rem, normal, 700, "Lato", 0em),
    body2-normal: mat.define-typography-level(0.875rem, 1.25rem, 400, "Lato", 0.01563rem),
    button: mat.define-typography-level(14px, 22px, 700, "Lato", 0em),
    caption: mat.define-typography-level(12px, 14px, 400, "Lato", 0em),
    input: mat.define-typography-level(inherit, 1.125, 700, "Lato", 0.25px),
    subtitle-1: mat.define-typography-level(20px, 24px, 700, "Lato", 0em),
    subtitle-2: mat.define-typography-level(15px, 28px, 400, "Lato", 0.15px),
    subtitle-3: mat.define-typography-level(14px, 22px, 400, "Lato", 0.1px),
    flat-button: mat.define-typography-level(12px, 14px, 800, "Lato", 1.5px),
    table-header: mat.define-typography-level(14px, 20px, 800, "Lato", 0em),
    label: mat.define-typography-level(14px, 20px, 700, "Lato", 0.25px),
    help-title: mat.define-typography-level(20px, 32px, "Lato", 0.25px),
    overline: mat.define-typography-level(12px, 14px, "Lato", 0.25px)
);

$gray-palette: (
    main: #8c8d8f,
    lighten-5: #f9f9f9,
    lighten-4: #f6f6f7,
    lighten-3: #e7e9ec,
    lighten-2: #dddfe2,
    lighten-1: #babcbe,
    darken-1: #696a6a,
    darken-2: #4e4e4f,
    darken-3: #3f3f41,
    darken-4: #2f2f30,
    secondary-text: #898C90,
    body-default: #333,
    divider-default: #e0e0e0,
    contrast: (main: white,
        lighten-5: black,
        lighten-4: black,
        lighten-3: black,
        lighten-2: black,
        lighten-1: black,
        darken-1: white,
        darken-2: white,
        darken-3: white,
        darken-4: white)
);
$brand-palette: (
    dark-blue: #223554,
    medium-blue: #5a7997,
    light-blue: #91afcd,
    teal: #bbdddd,
    light-teal: #eff5f5,
    blue: #2f80ed,
    contrast: (dark-blue: white,
        medium-blue: white,
        light-blue: white,
        teal: black,
        light-teal: black,
        blue: black)
);

$warm-palette: (
    dark-red: #c13353,
    light-red: #f9ebee,
    orange: #da5d27,
    light-orange: #fbefe9,
    contrast: (dark-red: white,
        light-red: black,
        orange: white,
        light-orange: black)
);

$action-palette: (
    primary-static: #2f9582,
    primary-hover: #257b6c,
    secondary-static: #5a7997,
    secondary-hover: #49637d,
    teal: #bbdddd,
    contrast: (primary-static: white,
        primary-hover: white,
        secondary-static: white,
        secondary-hover: white,
        teal: black)
);

$utility-palette: (
    red: #d32f2f,
    yellow: #ffb000,
    green: #48801a,
    blue-green: #2f9582,
    contrast: (red: white,
        yellow: black,
        green: white,
        blue-green: white)
);

$background-tints: (
    red-tint: #fcf0f0,
    yellow-tint: #fff7e6,
    green-tint: #f2f6ef,
    blue-tint: #eef7f5,
    dark-gray-tint: #ececec,
    light-gray-tint: #f6f5f6,
    contrast: (red-tint: black,
        yellow-tint: black,
        green-tint: black,
        blue-tint: black,
        dark-gray-tint: black,
        light-gray-tint: black)
);

$warning-palette: (
    main: #d32f2f,
    lighter: #f9ebee,
    darker: #c13353,
    200: #ff7a78,
    // For slide toggle,
    contrast: (main: white,
        lighter: black,
        darker: white,
        200: black)
);

$theme-primary: mat.define-palette($brand-palette, medium-blue, light-blue, dark-blue);
$theme-accent: mat.define-palette($action-palette, primary-static, teal, primary-hover);
$theme-warn: mat.define-palette($warning-palette, main, lighter, darker);

$foreground-palette: (
    base: rgba(black, 0.87),
    divider: map.get($gray-palette, divider-default),
    dividers: map.get($gray-palette, divider-default),
    disabled: map.get($gray-palette, lighten-1),
    disabled-button: rgba(black, 0.26),
    disabled-text: map.get($gray-palette, lighten-1),
    elevation: black,
    secondary-text: map.get($brand-palette, medium-blue),
    hint-text: map.get($gray-palette, main),
    accent-text: map.get($action-palette, primary-static),
    icon: map.get($action-palette, secondary-static),
    icons: map.get($action-palette, secondary-static),
    text: map.get($brand-palette, dark-blue),
    slider-min: rgba(black, 0.87),
    slider-off: rgba(black, 0.26),
    slider-off-active: rgba(black, 0.38),
    link-text: map.get($brand-palette, light-blue)
);

$background-palette: (
    background: #f9f9f9,
    status-bar: map.get($brand-palette, dark-blue),
    app-bar: map.get($brand-palette, dark-blue),
    hover: rgba(#fcfcfc, 0.04),
    card: white,
    dialog: white,
    tooltip: white,
    disabled-button: rgba(black, 0.12),
    raised-button: lighten(#fcfcfc, 5%),
    focused-button: rgba(black, 0.12),
    selected-button: darken(#fcfcfc, 20%),
    selected-disabled-button: darken(#fcfcfc, 30%),
    disabled-button-toggle: darken(#fcfcfc, 10%),
    unselected-chip: darken(#fcfcfc, 10%),
    disabled-list-option: darken(#fcfcfc, 10%),
    contrast: (app-bar: mat.get-contrast-color-from-palette($brand-palette, dark-blue),
        status-bar: mat.get-contrast-color-from-palette($brand-palette, dark-blue)),
    tints: $background-tints
);

:root {
    --dark-blue: #223554;
}

@include mat.core();

// Theme Config

body {
    --primary-color: mat.get-color-from-palette($theme-primary, default);
    --primary-lighter-color: mat.get-color-from-palette($theme-primary, lighter);
    --primary-darker-color: mat.get-color-from-palette($theme-primary, darker);
    --text-primary-color: mat.get-color-from-palette($theme-primary, default);
    --text-primary-lighter-color: mat.get-color-from-palette($theme-primary, lighter);
    --text-primary-darker-color: mat.get-color-from-palette($theme-primary, darker);
    --accent-color: mat.get-color-from-palette($theme-accent, default);
    --accent-lighter-color: mat.get-color-from-palette($theme-accent, lighter);
    --accent-darker-color: mat.get-color-from-palette($theme-accent, darker);
    --text-accent-color: mat.get-color-from-palette($theme-accent, default);
    --text-accent-lighter-color: mat.get-color-from-palette($theme-accent, lighter);
    --text-accent-darker-color: mat.get-color-from-palette($theme-accent, darker);
    --warn-color: mat.get-color-from-palette($theme-warn, default);
    --warn-lighter-color: mat.get-color-from-palette($theme-warn, lighter);
    --warn-darker-color: mat.get-color-from-palette($theme-warn, darker);
    --text-warn-color: mat.get-color-from-palette($theme-warn, default);
    --text-warn-lighter-color: mat.get-color-from-palette($theme-warn, lighter);
    --text-warn-darker-color: mat.get-color-from-palette($theme-warn, darker);

    --mdc-typography-button-text-transform: uppercase;
}

$light-colors: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $foreground-palette,
    background: $background-palette,
    brand: $brand-palette,
    gray: $gray-palette,
    warm: $warm-palette,
    action: $action-palette,
    utility: $utility-palette,
    tint: $background-tints
);

$theme: (
    color: $light-colors,
    typography: $fontConfig
);

@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($fontConfig);

@mixin core-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $primary: map.get($colors, primary);
    $accent: map.get($colors, accent);
    $warn: map.get($colors, warn);
    $brand: map.get($colors, brand);
    $gray: map.get($colors, gray);
    $utility: map.get($colors, utility);
    $tint: map.get($colors, tint);

    a {
        color: mat.get-color-from-palette($primary, lighter);
    }

    body {
        background: mat.get-color-from-palette($background, background);
        color: mat.get-color-from-palette($foreground, text);
    }

    h4 {
        font-size: 16px !important;
        font-weight: 700 !important;
    }

    .disabled {
        color: mat.get-color-from-palette($foreground, disabled-text);

        &.mat-icon {
            color: mat.get-color-from-palette($foreground, disabled-text);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($foreground, disabled-text);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($foreground, disabled-text);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($foreground, disabled-text);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($foreground, disabled-text) !important;
        }
    }

    .secondary {
        color: mat.get-color-from-palette($foreground, secondary-text);

        &.mat-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($foreground, secondary-text) !important;
        }
    }

    .error {
        color: mat.get-color-from-palette($warn, main);

        &.mat-icon {
            color: mat.get-color-from-palette($warn, main);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($warn, main);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($warn, main);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($warn, main);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($warn, main) !important;
        }
    }

    .warning {
        color: mat.get-color-from-palette($utility, yellow);

        &.mat-icon {
            color: mat.get-color-from-palette($utility, yellow);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($utility, yellow);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($utility, yellow);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($utility, yellow);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($utility, yellow) !important;
        }
    }

    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }

    .optional {
        font-style: italic;
    }

    .small {
        @include mat.typography-level($typography, caption);
    }

    .super-small {
        @include mat.typography-level($typography, caption);
        font-size: 10px;
        line-height: 14px;
    }

    .hint {
        color: mat.get-color-from-palette($foreground, hint-text);
    }

    .primary {
        color: mat.get-color-from-palette($primary, main);

        &.mat-icon {
            color: mat.get-color-from-palette($primary, main);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($primary, main);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($primary, main);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($primary, main);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($primary, main) !important;
        }
    }

    .primary-override {
        color: mat.get-color-from-palette($primary, main);
    }

    .accent {
        color: mat.get-color-from-palette($accent, main);

        &.mat-icon {
            color: mat.get-color-from-palette($accent, main);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($accent, main);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($accent, main);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($accent, main);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($accent, main) !important;
        }
    }

    .info {
        color: mat.get-color-from-palette($primary, lighter);

        &.mat-icon {
            color: mat.get-color-from-palette($primary, lighter);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($primary, lighter);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($primary, lighter);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($primary, lighter);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($primary, lighter) !important;
        }
    }

    .brand-medium {
        color: mat.get-color-from-palette($brand, medium-blue);

        &.mat-icon {
            color: mat.get-color-from-palette($brand, medium-blue);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($brand, medium-blue);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($brand, medium-blue);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($brand, medium-blue);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($brand, medium-blue) !important;
        }
    }

    .amber {
        color: mat.get-color-from-palette($brand, blue);

        &.mat-icon {
            color: mat.get-color-from-palette($utility, yellow);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($utility, yellow);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($utility, yellow);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($utility, yellow);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($utility, yellow) !important;
        }
    }

    .clickable {
        color: mat.get-color-from-palette($brand, blue);

        &.mat-icon {
            color: mat.get-color-from-palette($brand, blue);
        }

        &.mat-icon-button {
            color: mat.get-color-from-palette($brand, blue);
        }

        & .mat-icon {
            color: mat.get-color-from-palette($brand, blue);
        }

        & .mat-icon-button {
            color: mat.get-color-from-palette($brand, blue);
        }

        &.mat-mdc-footer-cell {
            color: mat.get-color-from-palette($brand, blue) !important;
        }
    }

    .button-disabled {
        color: rgba(black, 0.26);
        background: transparent;

        &.mat-icon {
            color: rgba(black, 0.26);
        }

        &.mat-icon-button {
            color: rgba(black, 0.26);
        }

        & .mat-icon {
            color: rgba(black, 0.26);
        }

        & .mat-icon-button {
            color: rgba(black, 0.26);
        }
    }

    .divider {
        background: mat.get-color-from-palette($foreground, divider);
    }

    .vdivider {
        background: mat.get-color-from-palette($foreground, divider);
    }

    .mat-mdc-standard-chip {
        --mdc-chip-label-text-size: 13px;
        --mdc-chip-label-text-weight: 700;

        &.warning {
            background-color: mat.get-color-from-palette($utility, yellow) !important;
            color: mat.get-contrast-color-from-palette($utility, yellow) !important;
        }

        &.mat-primary {
            background-color: mat.get-color-from-palette($brand-palette, medium-blue) !important;

            .mdc-evolution-chip__text-label {
                color: mat.get-contrast-color-from-palette($brand-palette, medium-blue) !important;
            }
        }

        &.mat-accent {
            background-color: mat.get-color-from-palette($action-palette, primary-static) !important;

            .mdc-evolution-chip__text-label {
                color: mat.get-contrast-color-from-palette($action-palette, primary-static) !important;
            }
        }

        &.mat-warn {
            background-color: mat.get-color-from-palette($warning-palette, main) !important;

            .mdc-evolution-chip__text-label {
                color: mat.get-contrast-color-from-palette($warning-palette, main) !important;
            }
        }
    }

    .account-menu {
        background: mat.get-color-from-palette($background, app-bar) !important;
        color: mat.get-color-from-palette($brand, light-teal) !important;
        border: 1px solid mat.get-color-from-palette($foreground, divider) !important;

        .divider {
            background: rgba(white, 0.12);
        }

        .account-header {
            background: lighten(mat.get-color-from-palette($background, app-bar), 10%);
            @include mat.typography-level($typography, subheading-2);
        }
    }

    .mat-mdc-menu-item.account-menu-item {
        color: mat.get-color-from-palette($brand, light-teal);

        .mat-icon {
            color: white;
        }

        &:hover {
            background: lighten(mat.get-color-from-palette($background, app-bar), 5%);
        }
    }

    .title {
        @include mat.typography-level($typography, title);
    }

    .title-1 {
        @include mat.typography-level($typography, subheading-1);
    }

    .title-2 {
        @include mat.typography-level($typography, subheading-2);
    }

    .breadcrumbs .breadcrumb .breadcrumb-button .mat-button-wrapper {
        display: flex;
        align-items: center;
    }

    .tile {
        background: mat.get-color-from-palette($background, card);
        box-shadow:
            0 1px 1px rgba(0, 0, 0, 0.1),
            0 2px 4px rgba(0, 0, 0, 0.05),
            0 1px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    .content-card {
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 2px 4px rgba(0, 0, 0, 0.05),
            0px 1px 8px rgba(0, 0, 0, 0.12);
        margin: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    .table {
        & .header {
            color: mat.get-color-from-palette($primary, default);
            @include mat.typography-level($typography, subheading-1);
            font-weight: 500;
        }

        & .body {
            &>.row {
                border: 1px solid mat.get-color-from-palette($foreground, divider);

                &.no-border {
                    border: none;
                }

                &:not(:last-child) {
                    border-bottom: none;
                }

                &:hover:not(.no-hover) {
                    background: mat.get-color-from-palette($background, disabled-button);
                }

                &.selected {
                    background: mat.get-color-from-palette($background, status-bar);
                }
            }

            &.striped {
                &>.row {
                    &:nth-of-type(even) {
                        background: mat.get-color-from-palette($background, card);
                    }

                    &:nth-of-type(odd) {
                        background: map-get(mat.$grey-palette, 100);
                    }
                }
            }
        }

        & .header>.row>.column,
        & .body>.row>.column {

            & .mat-icon,
            & .mat-icon-button {
                line-height: mat.line-height($typography, body-1);
                width: mat.line-height($typography, body-1);
                height: mat.line-height($typography, body-1);
            }

            & .mat-icon {
                font-size: mat.line-height($typography, body-1);
            }

            &.header {
                font-weight: bold;
                color: mat.get-color-from-palette($accent, default);
            }
        }
    }

    .uploader {
        & .drop-zone {
            border: dashed 1px mat.get-color-from-palette($foreground, divider);
            background-color: mat.get-color-from-palette($tint, green-tint);
            box-sizing: border-box;
            border-radius: 4px;

            &.over {
                border: dotted 2px mat.get-color-from-palette($accent, lighter);
            }
        }
    }

    div.mat-mdc-tooltip {
        .mdc-tooltip__surface {
            color: mat.get-color-from-palette($foreground, text) !important;
            @include mat.typography-level($typography, subheading-1);
            border: mat.get-color-from-palette($gray, lighten-3) 1px solid;
        }
    }

    .mdc-switch--selected.mdc-switch--checked {
        .mdc-switch__track {
            background-color: mat.get-color-from-palette($action-palette, teal);
        }

        .mdc-switch__shadow {
            background-color: mat.get-color-from-palette($accent, default);
        }
    }

    .mat-mdc-unelevated-button {
        @include mat.typography-level($typography, flat-button);
        text-transform: uppercase;
        background-color: transparent;

        .mat-icon {
            vertical-align: text-top !important;
            width: auto;
            font-size: 1rem;
        }
    }

    .mat-mdc-table {
        .mat-mdc-row {
            border-bottom-width: 0;
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
            padding: 0.625rem 0;
            @include mat.typography-level($typography, body-1);

            &:nth-of-type(even) {
                background: mat.get-color-from-palette($gray, lighten-5);
            }

            &:nth-of-type(odd) {
                background: inherit;
            }

            .mat-mdc-cell {
                margin: 0 0.5rem;
                border-bottom: 0;
                box-sizing: content-box !important;

                &:first-of-type {
                    padding-left: 1.5rem;
                }
            }
        }

        .mat-mdc-header-row {
            background: mat.get-color-from-palette($gray, lighten-4);
            border-bottom-width: 0;
            box-shadow: none;

            .mat-mdc-header-cell {
                margin: 0 0.5rem;
                color: mat.get-color-from-palette($brand, dark-blue);
                @include mat.typography-level($typography, table-header);
                border-bottom: 0;
                box-sizing: content-box !important;
            }
        }

        .mat-mdc-footer-row {
            border-bottom-width: 0;
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);

            .mat-mdc-footer-cell {
                margin: 0 0.5rem;
                color: mat.get-color-from-palette($brand, dark-blue);
                border-bottom: 0;
                box-sizing: content-box !important;
            }
        }

        .overflow {
            flex-grow: 0;
            flex-basis: auto;
            min-width: 2.5rem;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .overflow-badges {
            min-width: 2rem !important;
            padding-left: 0;
            padding-right: 1px;

            &:first-of-type {
                padding-left: 0 !important;
            }
        }
    }

    .mat-stepper-vertical-line::before {
        border-left-width: 0px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: mat.get-color-from-palette($gray, lighten-1);
    }

    .mat-mdc-progress-spinner.inline {
        display: inline-block;
        margin-right: 0.5rem;
    }

    .mat-mdc-dialog-title {
        @include mat.typography-level($typography, subheading-2);
        letter-spacing: auto;
        background: mat.get-color-from-palette($gray, lighten-4);
        color: mat.get-color-from-palette($brand, dark-blue);
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 0.25rem;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .builder-group-preview {
        @include mat.typography-level($typography, title);
    }

    .reference-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: rgb(241, 241, 241);
        z-index: 44;
        overflow: hidden;
        border-radius: 5px;
        margin: 0.5rem;

        &.primary {
            height: 1rem;
        }

        &.secondary {
            height: 10px;
        }

        &.icon {
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
        }

        &.task-icon {
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            flex: 0 0 auto;
        }

        &.chip {
            border-radius: 50%;
        }
    }

    .reference-loading {
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        background-image: linear-gradient(to left,
                rgba(251, 251, 251, 0.05),
                rgba(251, 251, 251, 0.3),
                rgba(251, 251, 251, 0.6),
                rgba(251, 251, 251, 0.3),
                rgba(251, 251, 251, 0.05));
        animation: reference-loading 1s infinite;
        z-index: 45;
    }

    @keyframes reference-loading {
        0% {
            left: -45%;
        }

        100% {
            left: 100%;
        }
    }

    @keyframes background-highlight {
        0% {
            background: mat.get-color-from-palette($tint, yellow-tint);
        }

        100% {
            background: inherit;
        }
    }

    .background-highlight {
        animation: background-highlight 10s;
    }

    .websocket-overlay {
        position: absolute;
        z-index: 10000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.75);
        color: white;
        display: flex;
        @include mat.typography-level($typography, title);

        .reconnect {
            width: 100%;
        }
    }
}

@import "src/iam/components/frames/authenticated/authenticated.frame.component.theme";
@import "src/common/components/breadcrumbs/breadcrumb.component.theme";
@import "src/common/components/tab-detail/tab-detail.component.theme";
@import "src/common/components/tab-frame/tab-frame.component.theme";
@import "src/admin/components/organization/organization.component.theme";
@import "src/program/components/program/program.component.theme";
@import "src/program/components/inquiry/inquiry.component.theme";
@import "src/program/components/discussion/discussion.component.theme";
@import "src/program/components/assignment/assignment.component.theme";
@import "src/integration/docusign/tabs/docusign-tabs.dialog.theme";
@import "src/program/components/shipment/shipment.component.theme";
@import "src/program/components/case/case.component.theme";
@import "src/intake/components/intake/intake.component.theme";
@import "src/program/components/document-repository/document.component.theme";
@import "src/program/components/team/team.component.theme";
@import "src/program/components/case-overview/case-overview.component.theme";
@import "src/program/components/communication/communication.component.theme.scss";
@import "src/program/components/help-dialog/help-dialog.component.theme.scss";
@import "/src/common/components/data-form/data-form.component.theme.scss";
@import "src/common/components/data-admin/data-form/data-form.component.theme.scss";
@import "src/admin/components/system/system-admin.component.theme.scss";
@import "src/admin/components/audit/admin-audit.component.theme.scss";
@import "/src/notifications/appnotification-drawer/appnotification-drawer.component.theme.scss";
@import "/src/program/components/document-repository/medalink-document/medalink-document-repository.component.theme.scss";
@import "src/program/components/assignment/task-table.component.theme";
@import "/src/admin/components/account/account.component.theme";

@include core-theme($theme);
@include authenticated-frame-theme($theme);
@include breadcrumb-theme($theme);
@include tab-detail-theme($theme);
@include tab-frame-theme($theme);
@include organization-theme($theme);
@include program-theme($theme);
@include inquiry-theme($theme);
@include discussion-theme($theme);
@include assignment-theme($theme);
@include docusign-theme($theme);
@include shipment-theme($theme);
@include case-theme($theme);
@include intake-theme($theme);
@include team-theme($theme);
@include document-upload-theme($theme);
@include case-overview-theme($theme);
@include communication-theme($theme);
@include help-theme($theme);
@include data-form-theme($theme);
@include data-form-editor-theme($theme);
@include system-admin-theme($theme);
@include audit-theme($theme);
@include notification-drawer-theme($theme);
@include medalink-document-repo-theme($theme);
@include task-table-theme($theme);
@include account-theme($theme);
