@mixin assignment-theme($theme) {
    $colors: mat.get-color-config($theme);
    $background: map-get($colors, background);
    $foreground: map-get($colors, foreground);
    $brand: map-get($colors, brand);
    $typography: mat.get-typography-config($theme);
    $gray: map-get($colors, gray);

    .custom-label {
        @include mat.typography-level($typography, label);
    }
    .task-title {
        color: mat.get-color-from-palette($brand, blue);
    }
    .task-title.disabled {
        pointer-events: none;
        color: map.get($gray, main);
        cursor: default;
    }
}
