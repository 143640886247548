@use "sass:map";
@use "@angular/material" as mat;

@mixin help-theme($theme) {
    $colors: mat.get-color-config($theme);
    $brand: map.get($colors, brand);
    $gray: map.get($colors, gray);
    $typography: mat.get-typography-config($theme);

    .help-header {
        background: mat.get-color-from-palette($brand, light-teal);
        .title {
            @include mat.typography-level($typography, help-title);
            color: mat.get-color-from-palette($gray, darken-3);
        }
        .secondary-title {
            @include mat.typography-level($typography, body-2);
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .border-bottom {
        border-bottom: 1px solid mat.get-color-from-palette($gray, lighten-1);
    }

    .border-top {
        border-top: 1px solid mat.get-color-from-palette($gray, lighten-1);
    }
    .section {
        .title {
            @include mat.typography-level($typography, body-1);
        }
        .text {
            color: mat.get-color-from-palette($brand, dark-blue);
        }
    }
}
