@use "@angular/material" as mat;

@mixin organization-theme($theme) {
    $typography: mat.get-typography-config($theme);
    $colors: mat.get-color-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $tint: map-get($colors, tint);

    .add-organization {
        z-index: 8;
        @include mat.elevation(8);
    }
    .settings-section-title {
        @include mat.typography-level($typography, title);
    }
    .settings-card {
        .sub-title {
            @include mat.typography-level($typography, subheading-2);
        }
    }
    .integration-code {
        .pre-formatted {
            border-radius: 0.5rem;
            border: 1px solid mat.get-color-from-palette($foreground, divider);
            background: mat.get-color-from-palette($background, app-bar);
        }
    }
    .settings-overview {
        background: mat.get-color-from-palette($tint, green-tint);
        color: mat.get-contrast-color-from-palette($tint, green-tint);
        .organization-name {
            @include mat.typography-level($typography, subheading-2);

            &.editing {
                @include mat.typography-level($typography, body-1);
            }
        }
    }
    .contact-information {
        margin-top: 2rem;
        @include mat.typography-level($typography, subheading-1);
    }
}
