@use "sass:map";
@use "@angular/material" as mat;

@mixin authenticated-frame-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $brand: map.get($colors, brand);

    .app-bar {
        background: mat.get-color-from-palette($background, app-bar);
        color: mat.get-contrast-color-from-palette($background, app-bar);

        .title {
            background: white;
            mask-image: url("../../../../assets/logo.svg");
            mask-repeat: no-repeat;
            mask-size: cover;
            -webkit-mask-image: url("../../../../assets/logo.svg");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        .features > button {
            color: mat.get-color-from-palette($brand, light-teal);
            opacity: 0.5;
            background: mat.get-color-from-palette($background, app-bar);
            @include mat.typography-level($typography, button);
            text-transform: none;

            &.selected {
                opacity: 1;

                &:after {
                    content: "";
                    border-top: 0.1875rem solid white;
                    position: absolute;
                    top: 3.3125rem;
                    left: 0;
                    right: 0;
                }
            }
        }
        .account-menu-trigger {
            background: mat.get-color-from-palette($background, app-bar);
            color: mat.get-contrast-color-from-palette($background, app-bar);
            @include mat.typography-level($typography, button);

            .avatar {
                color: white;
                font-size: 2rem;
            }
        }
    }

    .content-wrapper {
        .content {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
    .footer {
        background: mat.get-color-from-palette($background, app-bar);
        color: mat.get-contrast-color-from-palette($background, app-bar);
    }
}
