@use "sass:map";
@use "@angular/material" as mat;

//function to grab deep nested values, see: https://stackoverflow.com/questions/60448229/nesting-variables-within-a-variable
@function getDeepMapValue($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@mixin document-upload-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $brand: map.get($colors, brand);
    $action: map.get($colors, action);
    $gray: map.get($colors, gray);

    $brand-mediumblue: mat.get-color-from-palette($brand, medium-blue);

    .document-content .uploader {
        height: 11rem;
    }
    .uploader-text {
        color: $brand-mediumblue !important;
        @include mat.typography-level($typography, subtitle-2);
    }
    .upload-icon {
        color: $brand-mediumblue !important;
        transform: scale(2);
        position: static;
        left: 36.95%;
        right: 36.95%;
        top: 0%;
        bottom: 68.36%;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .file-button {
        background-color: mat.get-color-from-palette($action, primary-static) !important;
        color: getDeepMapValue($action, contrast, primary-static);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 16px !important;
        border-radius: 4px !important;
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .uploaded-file-card {
        background: getDeepMapValue($brand, contrast, medium-blue);
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 10px !important;
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 2px 4px rgba(0, 0, 0, 0.05),
            0px 1px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        flex: none;
        order: 1;
        flex-grow: 1;
    }
    .file-size {
        @include mat.typography-level($typography, caption);
        color: $brand-mediumblue !important;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 2px 0px;
    }
    .file-name {
        @include mat.typography-level($typography, caption);
        padding-left: 0 !important;
        color: #333333;
    }
    .progress-bar {
        position: relative;
        background-color: mat.get-color-from-palette($gray, lighten-4);
        height: 0.25rem;
    }
    .progress-bar > span {
        display: block;
        height: 100%;
        width: 35%;
        background-color: $brand-mediumblue;
        position: relative;
        overflow: hidden;
    }
    .folder {
        padding-right: 1.25rem;
    }
    .cancel {
        padding-left: 1.25rem;
    }
    .icon {
        color: $brand-mediumblue;
    }

    .document-image {
        box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.5);
    }
    .redacted {
        background: red;

        &:not(.visible):hover {
            background: rgba(255, 0, 0, 0.5);
        }
    }
    .redacted-add {
        background: red;
    }
    .redacted.visible {
        background: inherit;
        border: 2px solid red;
    }
    .redaction-actions {
        box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.5);
    }
    .redaction-header {
        box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.5);
        @include mat.typography-level($typography, "subheading-2");
    }
    .upload-dim {
        background-color: rgba(0, 0, 0, 0.85);
        color: white;
        @include mat.typography-level($typography, "subheading-2");
    }
}
