@use "@angular/material" as mat;

@mixin case-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $primary: map-get($colors, primary);
    $accent: map-get($colors, accent);
    $warn: map-get($colors, warn);
    $gray: map-get($colors, gray);
    $brand: map-get($colors, brand);
    .message-display {
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }
    .case-details .communications-tab .message-text {
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        border-radius: 0.25rem;
        background: mat.get-color-from-palette($background, background);
    }

    .mat-tab-label-active {
        border-right: 2px solid mat.get-color-from-palette($primary, darker) !important;
    }
    .case-details .mat-button-toggle-checked {
        background: mat.get-color-from-palette($accent, darker);
        color: mat.get-contrast-color-from-palette($accent, darker);
    }
    .case-details .communications-tab .message-header.selected {
        background: mat.get-color-from-palette($accent, default) !important;
        color: mat.get-contrast-color-from-palette($accent, default) !important;
    }
    .case-tabs .mat-tab-label {
        color: mat.get-color-from-palette($foreground, darker) !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: mat.get-color-from-palette($gray, lighten-1);
    }
    .label {
        @include mat.typography-level($typography, body-2);
        color: mat.get-color-from-palette($brand, dark-blue);
    }
    .view-label {
        @include mat.typography-level($typography, label);
        color: mat.get-color-from-palette($foreground, secondary-text);
    }
    .view-value {
        @include mat.typography-level($typography, input);
    }
}
