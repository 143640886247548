/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill-mention/dist/quill.mention.min.css";

body {
    margin: 0;
    height: 100vh;
    overflow: hidden;
}

a {
    text-decoration: none;
}

textarea {
    outline: none;
}

input {
    outline: none;
}

button {
    outline: none;
}

.wrapper {
    position: relative;

    &.absolute {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

//bdc-walkthrough also uses a class called container
//since this is the global file, our container class will override it and mess up the layout
//otherwise the margin would force all content in by 1rem and the background color would not reach the edge of the element
div.mat-mdc-menu-panel.bdc-walk-popup .container,
div.mat-menu-panel.bdc-walk-popup .container {
    margin: 0;
    position: inherit;
}

.container {
    position: relative;
    margin: 1rem;
}

.padded {
    padding-left: 1rem;
    padding-right: 1rem;
}

.center-align {
    align-items: center;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
    flex-direction: row;

    &.stack {
        flex-direction: column;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.between {
        justify-content: space-between;
    }

    &.even-spacing {
        justify-content: space-evenly;
    }
}

.spacer {
    flex: 1 1 auto;
}

.flexible {
    flex-grow: 1;
    flex-shrink: 1;
}

.fixed {
    flex-grow: 0;
    flex-shrink: 0;
}

.wrap {
    flex-wrap: wrap;
}

.no-wrap {
    flex-wrap: nowrap;
}

.break {
    flex-basis: 100%;
    width: 0;
    height: 0;
    overflow: hidden;
}

.full {
    width: 100%;

    &.stack {
        width: auto;
        height: 100%;
    }
}

.quarter {
    width: 25%;
}

.half {
    width: 50%;
}

.third {
    width: 33.33%;
}

.three-fourth {
    width: 75%;
}

.spinner {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.spinner-message {
    text-align: center;
    top: 50%;
    left: 50%;
    padding: 1rem;
}

.inline {
    width: auto;
    height: auto;
    vertical-align: bottom;
    line-height: inherit;
}

.divider {
    height: 1px;
    overflow: hidden;
    margin: 0;
}

.vdivider {
    width: 1px;
    overflow: hidden;
    margin: 0;
}

.account-menu {
    .account-header {
        padding: 0.625rem 1.25rem;
        text-align: center;
    }

    .mat-mdc-menu-item {
        min-width: 10rem;
        text-align: left;

        .mat-icon {
            margin-right: 0.5rem;
        }
    }

    .mat-mdc-menu-content {
        padding: 0;
    }

    .mat-mdc-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.small {
    & .mat-mdc-standard-chip {
        font-size: 9px;
        padding: 4px 6px;
        min-height: 16px;
    }
}

.scrollable {
    padding: 1rem;
    overflow-y: scroll;
    flex: 1 1 auto;
}

.no-padding {
    padding: 0;
}

.content-card {
    padding: 1rem;
    border-radius: 0.25rem;
}

.relative {
    position: relative;
}

.page-header {
    flex: 0 1 auto;
}

.sub-tabs {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    & .mat-tab-body-wrapper {
        flex: 1 1 auto;
    }
}

.list-item {
    cursor: pointer;
}

.row {
    position: relative;
    margin: 0.5rem 0;

    & > .column {
        margin: 0 0.5rem;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.flex {
        flex: 1 1 auto;

        & > .column {
            width: 0;
            flex: 1 1 auto;

            &.fixed {
                width: auto;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }
}

.table {
    position: relative;

    & .header > .row,
    & .body > .row {
        width: 100%;
        margin: 0;

        &.flex {
            flex-wrap: nowrap;
            flex-grow: 0;
        }

        & .column {
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
            padding: 0.25rem;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            &.vcenter {
                vertical-align: middle;
                align-items: center;
                align-content: center;
            }

            &:first-child {
                margin-left: 0.5rem;
            }

            &:last-child {
                margin-right: 0.5rem;
            }

            &.scroll {
                overflow: scroll;
            }

            &.wrap {
                overflow: auto;
                white-space: normal;
            }

            & .mat-icon-button,
            & > .mat-icon {
                margin-left: 0.25rem;
                margin-right: 0.25rem;
            }

            &.flex.fixed {
                flex-grow: 0;
                flex-shrink: 0;
            }

            &.icon-column {
                flex-grow: 0;
                flex-shrink: 0;
                width: 36px;
                justify-content: flex-end;
                justify-items: flex-end;
                text-align: right;
                align-content: center;
                align-items: center;
            }
        }
    }
}

.hidden {
    opacity: 0;
}

.clickable {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.object-details {
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
}

.table-cell-input {
    width: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.data-form-raido-container {
    padding-bottom: 2rem;

    .radio {
        padding-left: 1.5rem;
    }
}

.form-inline > .compound-property:not(:first-child) {
    margin-left: 0.5rem;
}

.option-spacer {
    padding-left: 0.25rem;
}

// attatch this to an empty div to dim whole screen
.dim {
    background-color: rgba(0, 0, 0, 0.85);
    position: absolute !important;
    z-index: 5000 !important;
    min-width: 100% !important;
    min-height: 100vh !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;

    &.container-only {
        height: auto;
        width: auto;
    }
}

.no-interactions {
    //per MED-949 past versions are view only.
    //instead of having to manage state across components, just disable all browser events
    // by attactching this class to the editor component
    pointer-events: none;
    user-select: none;
    cursor: disabled;

    .mat-datepicker-toggle {
        pointer-events: none;
    }
}

.not-found {
    justify-content: center;
}

.group-indent {
    margin-left: 1rem;
}

// attatch this to any element to 'escape' the dim and show it normally
// BE AWARE OF THE PARENT elements Z-INDEX or position. It can overide this styling , regardless of this class
// if the highlight is not working, most likely a parent has a z-index
// more info: https://coder-coder.com/z-index-isnt-working/
.highlight {
    z-index: 9999 !important;
    position: relative !important;
}

// JT - 5 May - Moved here because it's used in multiple components
.hidden-footer {
    height: 0;
    min-height: 0;
    visibility: hidden;
}

.hidden-rows {
    display: none;
}

/* ------- Post Fontello Custom Icons ------- */

.custom-icon {
    color: #000000;
    font-size: 16px;
}

/* ------- End Fontello Custom Icons ------- */

.mat-checkbox-layout {
    white-space: normal !important;

    .mat-checkbox-inner-container {
        margin-top: 0.25rem;
    }
}

.mat-mdc-tooltip {
    padding: 0.25rem 0.75rem !important;
    margin: 1rem !important;
    white-space: pre-line;
}
.org-tooltip-redaction {
    padding: 0 !important;
    margin: 0 !important;
}

/* Quill Editor CSS (it's styling is global so custom css changes have to be done here)*/
.ql-editor {
    padding: 0; // to remove padding form the quill-view component. Had to manually add padding back to the RTE component
}

mat-dialog-container {
    padding: 0 !important;
    min-height: max-content !important;
    position: relative;
}

.mat-mdc-dialog-title {
    display: flex !important;
    align-items: center;
    align-content: center;
    padding: 1rem !important;
    margin-bottom: 0 !important;
}

.mat-dialog-content {
    position: relative;
    margin: 0 !important;
    padding: 1rem 2rem !important;

    &.remove-styling {
        padding: 1rem 0 !important;
    }

    &.full {
        max-height: 100%;
    }
}

.mat-dialog-actions,
.object-actions {
    display: flex;
    padding: 0 !important;
    margin: 1rem 2rem !important;

    & > * {
        margin: 0 0.25rem;
    }

    & > button:first-child {
        margin-left: 0;
    }

    & > *:last-child {
        margin-right: 0;
    }

    .flex > & {
        flex-grow: 0;
    }
}

.mat-mdc-form-field.compact > .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
}

.list-header {
    margin-bottom: 2rem;
    align-content: center;
    align-items: center;
}

.list-container {
    margin: 2rem;
}

.list-search {
    min-width: 20rem;
    margin-right: 0.5rem;
    font-size: 12px;
}

.dt-slide .mat-slide-toggle-label .mat-slide-toggle-content {
    white-space: normal;
}
.dt-slide .mdc-form-field {
    align-items: start;
}
.dt-check .mdc-form-field {
    align-items: start;
    .mdc-label {
        padding-top: 0.5rem;
    }
}

.badgePadding {
    padding-left: 0.5rem;
}

.data-form-content-view {
    padding-top: 1.75rem;
}

.data-form-content-edit {
    padding-top: 0.5rem;
}

//https: //github.com/angular/components/issues/19846
//work around for mat-sidenav not emitting scrollin events
.mat-drawer,
.mat-sidenav {
    &[cdk-scrollable],
    &[cdkScrollable] {
        .mat-drawer-inner-container {
            overflow: visible;
        }
    }
}

.center-chips {
    display: flex;
    width: 100%;

    .mdc-evolution-chip-set__chips {
        justify-content: center;
    }
}

.mdc-evolution-chip-set__chips {
    max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
}

.mdc-evolution-chip__text-label {
    text-align: center !important;
    word-wrap: break-word !important;
}

// Removed the global styling for the mat-form-field.  any margin settings should be applied
// to individual instances of the mat-form-field instead of globally

.margin-right {
    margin-right: 0.5rem;
}
.margin-top {
    margin-top: 1rem;
}
.margin-bottom {
    margin-bottom: 1rem;
}
.mat-mdc-tab-header {
    margin-bottom: 1rem;
}

.settings-section-content {
    margin-top: 1rem;
    margin-left: 0.5rem;
}

.card-grid {
    display: grid;
    gap: 1rem;
}

.example-card {
    padding: 0.75rem;
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-column {
    flex: 1;
    text-align: left;
    padding: 0 0.5rem;
}

.card-button {
    flex: none;
}

.card-header {
    font-size: 0.9rem;
    font-weight: bold !important;
}

.card-text-content {
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-mdc-form-field {
    padding-top: 0.25rem;
}

.country-flag-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
    margin-right: 8px;
}
