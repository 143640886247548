@use "sass:map";
@use "@angular/material" as mat;

@mixin system-admin-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $gray: map.get($colors, gray);

    .log-display {
        background-color: mat.get-color-from-palette($gray, lighten-1);
        color: mat.get-contrast-color-from-palette($gray, lighten-1);
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        border-radius: 4px;
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 2px 4px rgba(0, 0, 0, 0.05),
            0px 1px 8px rgba(0, 0, 0, 0.12);
        @include mat.typography-level($typography, caption);
        font-family: "Lucida Console", monospace;
    }
}
