@use "@angular/material" as mat;

@mixin program-theme($theme) {
    $typography: mat.get-typography-config($theme);
    $colors: mat.get-color-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);

    .add-program {
        z-index: 8;
        @include mat.elevation(8);
    }

    .settings-section-title {
        @include mat.typography-level($typography, title);
    }

    .integration-code {
        .pre-formatted {
            border-radius: 0.5rem;
            border: 1px solid mat.get-color-from-palette($foreground, divider);
            background: mat.get-color-from-palette($background, app-bar);
        }
    }
}
