@use "sass:map";
@use "@angular/material" as mat;

@mixin tab-detail-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $brand: map.get($colors, brand);
    $tints: map.get($background, tints);

    .detail-tab-group {
        .tabs {
            .tab-button {
                @include mat.typography-level($typography, body-2);
                text-transform: none;
                height: 3.5rem;
                justify-content: start;

                &.selected {
                    background: mat.get-color-from-palette($tints, light-gray-tint);
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
        }
    }
}
