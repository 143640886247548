@use "sass:map";
@use "@angular/material" as mat;
@mixin medalink-document-repo-theme($theme) {
    $colors: mat.get-color-config($theme);
    $brand: map.get($colors, brand);
    $typography: mat.get-typography-config($theme);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $accent: map.get($colors, accent);
    $gray: map.get($colors, gray);
    $tint: map.get($colors, tint);
    $brand-mediumblue: mat.get-color-from-palette($brand, medium-blue);

    .drop-zone {
        border-radius: 0.25rem;
        border: dashed 1px mat.get-color-from-palette($foreground, divider);
        background-color: mat.get-color-from-palette($tint, green-tint);

        box-sizing: border-box;

        &.over {
            border: solid 2px var(--main-brand-color-brand, #16969c);
            background-color: mat.get-color-from-palette($gray, lighten-2);
        }
    }

    .uploader-text {
        color: $brand-mediumblue !important;
        @include mat.typography-level($typography, subtitle-2);
    }

    .progress-bar {
        position: relative;
        background-color: mat.get-color-from-palette($gray, lighten-4);
        height: 0.25rem;
    }
    .progress-bar > span {
        display: block;
        height: 100%;
        width: 35%;
        background-color: $brand-mediumblue;
        position: relative;
        overflow: hidden;
    }

    .documentContainer {
        border: 1px solid #ccc;
    }

    .actionsContainer {
        background: var(--main-black-color-black-95, #f2f2f3);
    }
}
