@use "@angular/material" as mat;

@mixin intake-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map-get($colors, primary);
    $background: map-get($colors, background);
    $foreground: map-get($colors, foreground);

    .intake-app-bar {
        background: mat.get-color-from-palette($background, app-bar);
        color: mat.get-contrast-color-from-palette($background, app-bar);

        .title {
            @include mat.typography-level($typography, headline-6);
            color: mat.get-contrast-color-from-palette($background, app-bar);
        }

        .powered-by {
            @include mat.typography-level($typography, caption);
            color: mat.get-contrast-color-from-palette($background, app-bar);
            line-height: 12px;

            .ea-title {
                @include mat.typography-level($typography, button);
            }
        }
    }
    .intake-content-wrapper {
        .content {
            background: mat.get-color-from-palette($background, background);
            color: mat.get-color-from-palette($foreground, text);
        }
        .footer {
            background: lightgray;
            color: mat.get-color-from-palette($foreground, text);

            & a {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
        .intake-form {
            .intake-header {
                @include mat.typography-level($typography, title);
            }
        }
        .no-intake {
            @include mat.typography-level($typography, headline-4);
        }

        .intake-role {
            @include mat.typography-level($typography, headline-4);
            font-weight: 500;

            .ima {
                @include mat.typography-level($typography, subheading-2);
                font-weight: 400;
            }

            .role-button {
                background: white;
                text-transform: uppercase;
            }
        }

        .country-product {
            @include mat.typography-level($typography, headline-4);
            font-weight: 500;

            .dropdown-label {
                @include mat.typography-level($typography, subheading-2);
                font-weight: 400;
            }
        }

        .intake-data-form {
            @include mat.typography-level($typography, headline-4);
            font-weight: 500;
        }

        .legal-checks {
            .mat-mdc-checkbox .mdc-form-field {
                @include mat.typography-level($typography, subheading-2);
                font-weight: 500;
            }

            mat-checkbox {
                margin-bottom: 1rem;
            }
        }
    }

    .submitted-subtitle {
        @include mat.typography-level($typography, subtitle-2);
    }
}
