@use "sass:map";
@use "@angular/material" as mat;

@mixin audit-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map.get($colors, primary);
    $background: map.get($colors, background);
    $foreground: map.get($colors, foreground);
    $tints: map.get($colors, tint);
    $brand: map.get($colors, brand);
    $gray: map.get($colors, gray);

    .audit-state {
        background: mat.get-color-from-palette($gray, lighten-1);
        color: mat.get-contrast-color-from-palette($gray, lighten-1);
        border: 1px mat.get-color-from-palette($foreground, divider);
        box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 2px 4px rgba(0, 0, 0, 0.05),
            0px 1px 8px rgba(0, 0, 0, 0.12);
    }

    .selected-entry {
        background: mat.get-color-from-palette($brand, light-blue) !important;
        color: mat.get-contrast-color-from-palette($brand, light-blue) !important;

        .secondary {
            color: mat.get-contrast-color-from-palette($brand, light-blue) !important;
        }
    }
}
