@use "@angular/material" as mat;

@mixin shipment-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map-get($colors, primary);

    .shipping-details {
        .title {
            color: mat.get-color-from-palette($primary, darker);
            @include mat.typography-level($typography, headline);
        }
    }
}
