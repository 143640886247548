@use "sass:map";
@use "@angular/material" as mat;

@mixin breadcrumb-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typo: mat.get-typography-config($theme);
    $foreground: map.get($colors, foreground);
    $brand: map.get($colors, brand);

    .breadcrumbs {
        background: mat.get-color-from-palette($brand, light-teal);
        color: mat.get-contrast-color-from-palette($brand, light-teal);

        .breadcrumb {
            &:not(:last-child):after {
                color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
