@use "sass:map";
@use "@angular/material" as mat;

@mixin data-form-theme($theme) {
    $typography: mat.get-typography-config($theme);
    $colors: mat.get-color-config($theme);
    $primary: map.get($colors, primary);
    $tint: map.get($colors, tint);
    $foreground: map.get($colors, foreground);
    $accent: map.get($colors, accent);

    .text-color {
        color: black;
    }
    .subtitle {
        @include mat.typography-level($typography, subtitle-2);
    }
    .medical-history {
        @include mat.typography-level($typography, label);
    }
    .drop-zone {
        border: dashed 1px mat.get-color-from-palette($foreground, divider);
        background-color: mat.get-color-from-palette($tint, green-tint);
        box-sizing: border-box;
        border-radius: 4px;
        &.over {
            border: dotted 2px mat.get-color-from-palette($accent, lighter);
        }
    }
}
