@use "@angular/material" as mat;

@mixin account-theme($theme) {
    $colors: mat.get-color-config($theme);
    $gray: map-get($colors, gray);

    .add-account {
        z-index: 8;
        @include mat.elevation(8);
    }

    .team-owner{
        @include mat.elevation(2);
        background-color: #F7F7F7
    }
}
