@use "@angular/material" as mat;

@mixin docusign-theme($theme) {
    $colors: mat.get-color-config($theme);
    $background: map-get($colors, background);
    $foreground: map-get($colors, foreground);
    $amber: map-get($colors, amber);

    .sign-here {
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        background-color: rgba(251, 215, 56, 0.75);

        &:active {
            box-shadow:
                0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }
    }
}
