@use "@angular/material" as mat;

@mixin tab-frame-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $primary: map-get($colors, primary);
    $accent: map-get($colors, accent);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);

    .mat-chip.mat-standard-chip.close-all {
        @include mat.typography-level($typography, button);
        font-size: 10px;
        background-color: mat.get-color-from-palette($accent, darker);
        color: mat.get-contrast-color-from-palette($accent, darker);
    }
    .gutter {
        background: mat.get-color-from-palette($foreground, divider);
    }

    .tab {
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

        .active {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: mat.get-color-from-palette($primary, lighter);
        }
        .changed {
            position: absolute;
            top: 0.125rem;
            right: 0.125rem;
            width: 3px;
            border-radius: 50%;
            background: mat.get-color-from-palette($accent, main);
        }
    }
    .tab-content {
        background-color: mat.get-color-from-palette($foreground, disabled-button);

        .title {
            color: mat.get-color-from-palette($primary, darker);
            @include mat.typography-level($typography, headline);
        }

        .breadcrumb {
            color: mat.get-color-from-palette($primary, main);
        }
    }
}
