@use "@angular/material" as mat;

@mixin case-overview-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $primary: map-get($colors, primary);
    $accent: map-get($colors, accent);
    $warn: map-get($colors, warn);
    $gray: map-get($colors, gray);
    $brand: map-get($colors, brand);
    $tint: map-get($colors, tint);

    .case-overview {
        &.pharma {
            background: mat.get-color-from-palette($tint, yellow-tint);
            color: mat.get-contrast-color-from-palette($tint, yellow-tint);
        }

        &.physician {
            background: mat.get-color-from-palette($brand, light-teal);
            color: mat.get-contrast-color-from-palette($brand, light-teal);
        }

        .header-panel {

                box-shadow: inset -1px 0px 1px rgba(0, 0, 0, 0.12);

        }
        .case-name {
            @include mat.typography-level($typography, subheading-2);

            &.editing {
                @include mat.typography-level($typography, body-1);
            }
        }
    }
}
