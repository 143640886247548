@use "@angular/material" as mat;

@mixin discussion-theme($theme) {
    $colors: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);
    $foreground: map-get($colors, foreground);
    $background: map-get($colors, background);
    $primary: map-get($colors, primary);
    $accent: map-get($colors, accent);
    $warn: map-get($colors, warn);

    .discussion-container {
        & .message-list {
            & .message {
                background-color: mat.get-color-from-palette($accent, main, 0.75);
                color: mat.get-contrast-color-from-palette($accent, main);
                border: 1px solid mat.get-color-from-palette($foreground, divider);
                border-radius: 0.5rem;
                box-shadow:
                    0 3px 1px -2px rgba(0, 0, 0, 0.2),
                    0 2px 2px 0 rgba(0, 0, 0, 0.14),
                    0 1px 5px 0 rgba(0, 0, 0, 0.12);

                &.sender {
                    background-color: mat.get-color-from-palette($accent, lighter, 0.75);
                    color: mat.get-contrast-color-from-palette($accent, lighter);
                }

                & .header {
                    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
                }
            }
        }

        & .send-button .mat-icon-button {
            @include mat.typography-level($typography, display-1);

            & .mat-icon {
                line-height: inherit;
                width: auto;
                height: auto;
                font-size: inherit;
            }
        }
    }
}
