@mixin task-table-theme($theme) {
    $colors: mat.get-color-config($theme);

    $brand: map-get($colors, brand);
    $typography: mat.get-typography-config($theme);
    $gray: map-get($colors, gray);
    $utility: map-get($colors, utility);
    .task-action{
        color: mat.get-color-from-palette($brand, blue);
    }
    .disabled {
        pointer-events: none;
        color: map.get($gray, main);
        cursor: not-allowed;
    }

    .completed-icon{
        color: mat.get-color-from-palette($utility, green);
    }
}
